// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---theme-src-layout-docs-tsx": () => import("./../../../../theme/src/layout/docs.tsx" /* webpackChunkName: "component---theme-src-layout-docs-tsx" */),
  "component---theme-src-layout-print-tsx": () => import("./../../../../theme/src/layout/print.tsx" /* webpackChunkName: "component---theme-src-layout-print-tsx" */),
  "component---theme-src-layout-search-tsx": () => import("./../../../../theme/src/layout/search.tsx" /* webpackChunkName: "component---theme-src-layout-search-tsx" */),
  "component---theme-src-pages-404-js": () => import("./../../../../theme/src/pages/404.js" /* webpackChunkName: "component---theme-src-pages-404-js" */)
}

