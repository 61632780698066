module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/docs/docs/theme/src/layout/index.tsx"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null,[null,{"padSpaceAfter":true}]],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/docs/docs/theme/plugins/fix-links/index.js"},{"resolve":"/home/runner/work/docs/docs/theme/plugins/fix-mermaid-pre/index.js"},{"resolve":"gatsby-remark-mermaid","options":{"mermaidOptions":{"arrowMarkerAbsolute":false,"fontFamily":"'trebuchet ms', verdana, arial"}}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"","className":"autolink"}},"gatsby-remark-prismjs",{"resolve":"gatsby-remark-images","options":{"maxWidth":896,"disableBgImage":true,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["png","jpg","jpeg"]}},{"resolve":"/home/runner/work/docs/docs/theme/plugins/fix-mermaid-post/index.js"},{"resolve":"/home/runner/work/docs/docs/theme/plugins/check-links/index.js","options":{"exceptions":["/broken"]}}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/docs/docs/example"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"@committed/docs-theme","short_name":"docs","start_url":"/","background_color":"#3E3E3E","theme_color":"#FFBB00","display":"browser","icon":"/home/runner/work/docs/docs/example/Avatar.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7c1f2034c57e0a1ccf68da9ef95e312f"},
    }]
